import { init, browserTracingIntegration } from "@sentry/browser";

init({
  dsn: "https://81e60052237226a4aad02ce88d40cf61@o4506104309874688.ingest.sentry.io/4506104375672832",
  // https://docs.sentry.io/platforms/javascript/configuration/sampling/#sampling-error-events
  sampleRate: 0.25,
  integrations: [browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.01, // Capture 1% of the transactions
  allowUrls: [/https:\/\/soundraw\.io/],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/soundraw\.io\/api/],
  ignoreErrors: [
    "Error loading https://soundraw-storage.com",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // ECONNABORTED connection interrupted by the client
    "AxiosError: Request aborted",
    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
    "Non-Error promise rejection captured",
    // https://stackoverflow.com/a/27917744
    "undefined is not an object (evaluating '__gCrWeb.edgeTranslate.detectPageState')",
    // CSP error TODO: remove
    "script-src",
    // A script tag fetches HTML instead of JS
    "Unexpected token '<'",
    // Ignores unknown fetch errors mostly due to external resources failing to fetch
    // https://adithya.dev/sentry-unhandled-failed-to-fetch/
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Cancelled",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
